export const senderService = {
  async getAllSenders(page = 1, limit = 50) {
    const supabase = useSupabaseClient();

    const start = (page - 1) * limit;
    const end = start + limit - 1;

    return supabase
      .from('senders')
      .select('*', {count: 'exact'})
      .is('deleted_at', null)
      .order('name', {ascending: true})
      .range(start, end)
      .then(response => ({
        senders: response.data || [],
        count: response.count || 0
      }));
  },

  async getSenderById(id) {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase.from('senders').select('*').eq('id', id).single();
    if (error) throw error;
    return data;
  },

  async createSender(senderData) {
    const supabase = useSupabaseClient();
    const user = useSupabaseUser();

    if (!user.value) throw new Error('User not authenticated');
    senderData.created_by = user.value.id;

    const {data, error} = await supabase.from('senders').insert(senderData).select().single();
    if (error) throw error;
    return data;
  },

  async updateSender(id, senderData) {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase
      .from('senders')
      .update(senderData)
      .eq('id', id)
      .select()
      .single();
    if (error) throw error;
    return data;
  },

  async searchSenders(query) {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase.from('senders').select('*').ilike('name', `%${query}%`);
    if (error) throw error;
    return data;
  },

  async updateDocumentSenders(senderIds, targetSenderId) {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase
      .from('documents')
      .update({sender_id: targetSenderId})
      .in('sender_id', senderIds);

    if (error) throw error;
    return data;
  },

  async softDeleteSender(id) {
    const supabase = useSupabaseClient();
    const {error} = await supabase.from('senders').update({deleted_at: new Date()}).eq('id', id);
    if (error) throw error;
    return true;
  },

  async hardDeleteSender(id) {
    const supabase = useSupabaseClient();
    const {error} = await supabase.from('senders').delete().eq('id', id);
    if (error) throw error;
    return true;
  }
};
