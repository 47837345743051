import {defineStore} from 'pinia';
import {senderService} from '~/lib/services/sender.service.js';
import {useDocumentStore} from '~/stores/document.store.js';

export const useSenderStore = defineStore('sender', {
  state: () => ({
    senders: [],
    documentsForSender: [],
    trashSenders: [],
    currentSender: null,
    totalSenders: 0,
    isLoading: false,
    error: null
  }),

  actions: {
    async fetchAllSenders(page = 1, limit = 50) {
      this.isLoading = true;
      try {
        const {senders, count} = await senderService.getAllSenders(page, limit);
        this.senders = senders;
        this.totalSenders = count;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchSenderById(id) {
      return new Promise((resolve, reject) => {
        senderService
          .getSenderById(id)
          .then(sender => {
            this.currentSender = sender;

            const index = this.senders.findIndex(sender => sender.id === sender.id);
            if (index !== -1) {
              this.senders[index] = {...this.senders[index], ...sender};
            } else {
              this.senders.push(sender);
            }
            resolve(sender);
          })
          .catch(error => {
            console.error('Error fetching sender:', error);
            reject(error);
          });
      });
    },

    async createSender(senderData) {
      this.isLoading = true;
      try {
        const newSender = await senderService.createSender(senderData);
        this.senders.push(newSender);
        return newSender;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async updateSender(id, senderData) {
      this.isLoading = true;
      try {
        const updatedSender = await senderService.updateSender(id, senderData);
        const index = this.allSenders.findIndex(sender => sender.id === id);
        if (index !== -1) {
          this.senders[index] = updatedSender;
        }
        if (this.currentSender && this.currentSender.id === id) {
          this.currentSender = updatedSender;
        }
        return updatedSender;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async searchSenders(query) {
      this.isLoading = true;
      try {
        return await senderService.searchSenders(query);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async mergeSenders({targetSenderId, sendersToMerge}) {
      this.isLoading = true;
      try {
        // Update all documents from the merged senders to point to the target sender
        const documentStore = useDocumentStore();
        const senderIds = sendersToMerge.map(sender => sender.id);

        // Update documents to point to target sender
        await senderService.updateDocumentSenders(senderIds, targetSenderId);

        // Delete the merged senders
        for (const sender of sendersToMerge) {
          await senderService.softDeleteSender(sender.id);
          this.senders = this.senders.filter(s => s.id !== sender.id);
        }

        // Refresh the documents list
        await documentStore.fetchDocuments();

        return true;
      } catch (error) {
        this.error = error.message;
        throw error;
      } finally {
        this.isLoading = false;
      }
    },

    async softDeleteSender(id) {
      this.isLoading = true;
      try {
        await senderService.softDeleteSender(id);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async hardDeleteSender(id) {
      this.isLoading = true;
      try {
        await senderService.hardDeleteSender(id);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    }
  },

  getters: {
    getSenderById: state => id => state.senders.find(sender => sender.id === id)
  }
});
